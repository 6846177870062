export const prettynum = n => new Intl.NumberFormat('en-US').format(n)

export const CAROUSEL_SCALE = 400

export const style = {
    div: {
        paddingLeft:'8pt',
    },
    carousel: {
        height: CAROUSEL_SCALE,
        width: CAROUSEL_SCALE*1.3,
    },
}
