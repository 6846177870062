import { Suspense } from 'react'

import Loading from '../Loading'

const REFClove = ({reader, style}) => {
    const refUrl = "https://www.youtube.com/channel/UCU0LJ9et5Tb3m7COFzShgLg"

    const Handler = ({reader, style}) => {
        return <>
        <div className="carouser">
            <video width={style.carousel.width} height={style.carousel.height} controls>
                <source src="/gfx/birbs/Clove.mp4" type="video/mp4" />
            </video>
        </div>
        <div style={style.div}><p style={{textAlign:'justify'}}>
            <img src="/gfx/birbs/ref-Clove-31676765.760000005_image.png" alt="Clove" style={{paddingLeft:12, borderWidth:2, borderColor:'black', width:160, float:'right'}}/>
            <b>Clove</b>, one of my favorite owlets has unfortunately passed &ndash; verified as of 11th Dec 2021 &ndash; due a
                power company's (<a href="https://www.facebook.com/northernpowergrid/"
                target="stupid-power-company">Northern Powergrid</a>) stubborn stupidity with their
                exposed wirings.</p>
        </div>
        </>
    }
    
    return <>
    <h2>Clove &#x2764; @ <a href={refUrl} target="_ref_birbs">Robert E Fuller, UK</a></h2>
    <div className="rowflex">
        <Suspense fallback={<Loading />}>
            <Handler reader={reader} style={style}/>
        </Suspense>
    </div>
    </>
}

export default REFClove
