import { Suspense } from 'react'

import BirbCarousel from './BirbCarousel'
import Loading from '../Loading'
import { prettynum } from '../../scale'

const MISC = ({reader, style}) => {
    const Handler = ({reader, style}) => {
        const data = reader()
        return <>
        <div className="carouser"><BirbCarousel json={data.b} style={style.carousel}/></div>
        <div style={style.div}>
            Birds and other animalses. Locations are all over the world, more or less.
            <p>{prettynum(data.c)} pics, {data.s} total.</p>
        </div>
        </>
    }

    return <>
    <h2>Misc, mainly Birbs!</h2>
    <div className="rowflex">
        <Suspense fallback={<Loading />}>
            <Handler reader={reader} style={style}/>
        </Suspense>
    </div>
    </>
}

export const reader = () => fetch("https://scifi.linkpc.net/gfx/birbs.php?mse").then(r => r.json())

export default MISC
