import React from 'react'

const WF_CSS = () => <div id="wf-css">
    <h2>Warframe :: Cephalon Simaris Synthesis</h2>
    <a href="https://play.google.com/store/apps/details?id=net.linkpc.scifi.wf.css" target="google_play_store"><img src="/gfx/google-play-store.svg" width="250" alt="Google Play Store" style={{float:'right',paddingLeft:'8pt'}} /></a>
    <p>
        A simple &ndash;and very niche&ndash; companion app to figure out where <em>Cephalon
            Simaris'</em> targets dwell. More @ the <a href="/wf/css">app's home page</a>.
    </p>
    <p>P.s. If you don't play <a href="https://warframe.com"
                                 style={{textDecoration:'none'}}
                                 target="warframe_com"><em>Warframe</em></a> on
            PC/consoles and/or do not own an <span className="tooltip">Android<span className="tooltip-text">A phone, tablet, TV, or an Android emulator.</span></span>,
            then this app is <b><span className="not">not</span></b> for you.
            </p>
</div>

export default WF_CSS
