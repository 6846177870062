import { useState } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'

const DropDownContainer = styled("div")`
    width: 10.5em;
    position: absolute;
    top: 259px;
    left: var(--header-width);
    cursor: pointer;
    background-color: #456789;
    overflow: hidden;
    border-radius: 40px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 40px;
    z-index: 999;
    margin: 0 auto;`

const DropDownHeader = styled("div")`
    margin-bottom: 0.2em;
    padding: 0.4em 2em 0.4em 1em;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    font-weight: 500;
    font-size: 1.3rem;
    color: #3faffa;
    background: #123456;`

const DropDownListContainer = styled("div")``

const DropDownList = styled("ul")`
    padding: 0;
    margin: 0;
    padding-left: 1em;
    background: #ffffff;
    border-style: dashed;
    border-color: black;
    border-left-width: 0px;
    border-top-width: 0px;
    border-right-width: 8px;
    border-bottom-width: 8px;
    border-radius: 40px;
    border-top-left-radius: 10px;
    box-sizing: border-box;
    color: #3faffa;
    font-size: 1.3rem;
    font-weight: 500;
    &:first-child {
        padding-top: 0.1em;
    }`

const ListItem = styled("li")`
    list-style: none;
    margin-bottom: 0.8em;
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;`

const Menu = (props) => {
	const [isOpen, setOpen] = useState(false)
    const [selectedShow, setSelectedShow] = useState(null)

	const toggle = () => setOpen(!isOpen)

	const onOptionClicked = (value) => () => {
        let where2
        if (typeof value===typeof []) {
            where2 = value[0]
            setSelectedShow(value[1])
        } else {
            where2 = value[1]
            setSelectedShow(value)
        }
		setOpen(false)
        props.history.push(`/${where2}`)
	}

    return (<DropDownContainer onBlur={() => setOpen(false)} tabIndex={0}>
        <DropDownHeader onClick={toggle}>
            <span className="no-touch">{selectedShow || "where to?"}</span>
        </DropDownHeader>
        {isOpen && (
            <DropDownListContainer>
                <DropDownList>
                    {props.options.map(opt => (typeof opt === typeof [])
                        ? <ListItem onClick={onOptionClicked(opt)} key={Math.random()}>{opt[1]}</ListItem>
                        : <ListItem onClick={onOptionClicked(opt)} key={Math.random()}>{ opt  }</ListItem>
                    )}
                </DropDownList>
            </DropDownListContainer>
        )}
    </DropDownContainer>)
}

export default withRouter(Menu)
