import Carousel from 'react-gallery-carousel'

const BirbCarousel = ({json, style}) => {
    const imgs = []
    json.forEach(element => { imgs.push({src: `https://scifi.linkpc.net/gfx/${element}`}) })
    return <Carousel images={imgs} style={style}
                     shouldLazyLoad={true}
                     hasIndexBoard={false}
                     hasMediaButton={false} />
}

export default BirbCarousel
