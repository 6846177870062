import React from 'react'
import { Route, Switch } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

const Elixir = () => {
    const { path } = useRouteMatch()

    return(<>
{/* eslint-disable-next-line */}
<h1><a id="lang_e">Elixir</a></h1>

<Switch>
    <Route path={`${path}/`}>
{/* eslint-disable-next-line */}
<h2><a id="elixir_base64">Base64</a></h2>
<pre>{`defmodule Base64 do
  @moduledoc """
  Documentation for `}{'Base64'}{`.
  """
  use Bitwise

  defp base64_set do 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/' end
  defp base64_str do "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/" end

  defp split(num, i) when num > 0 do
    case num do
      3 -> for <<x::binary-3 <- i>>, do: x
      _ -> for <<x::binary-4 <- i>>, do: x
    end
  end

  @doc """
  Base64-encode a string or atom.

  ## Examples

      iex> Base64.encode("This is a string")
      "VGhpcyBpcyBhIHN0cmluZw=="

  """
  def encode(s) when is_atom(s) do encode Atom.to_string(s) end
  def encode(s) do
    [pad, s] = case (rem(byte_size(s), 3)) do
        1-> ["==", s <> <<0,0>>]
        2-> ["=",  s <> <<0>>  ]
        _-> ["",   s           ]
    end
    String.slice((split(3, s) |> Enum.map(fn <<a,b,c>> ->
      n = ((0xff &&& a)<<<16) + ((0xff &&& b) <<<8) + (0xff &&& c)
      e = fn (x,y) -> Enum.at(base64_set(), (0x3f &&& (x >>> y))) end
      <<e.(n,18), e.(n,12), e.(n,6), e.(n,0)>>
    end) |> Enum.join ), 0..-(String.length(pad)+1)) <> pad
  end

  @doc """
  Decode base64 encoded chunk.

  ## Examples

      iex> Base64.decode("VGhpcyBpcyBhIHN0cmluZw==")
      "This is a string"

  """
  def decode(s) do
    if (rem( String.length(s), 4) !== 0) do
        raise {:error, "Data { s } does not comply with BASE64 encoding spec"}
    end
    s = (fn
      c,_,s when c == "==" -> String.slice(s, 0..-3) <> "AA"
      _,c,s when c == "="  -> String.slice(s, 0..-2) <> "A"
      _,_,s -> s
    end).(String.slice(s, (-2)..(-1)), String.slice(s, -1..-1), s)
    id = fn x -> {r,_} = :binary.match(base64_str(), List.to_string([x])); r end
    [h|t] = split(4,s) |> Enum.map(fn <<a,b,c,d>> ->
        n = (id.(a) <<< 18) + (id.(b) <<< 12) + (id.(c) <<<  6) +  id.(d)
        <<0xff &&& (n >>> 16), 0xff &&& (n >>>  8), 0xff &&&  n>>
      end) |> Enum.reverse
    (Enum.reverse(t) |> Enum.join)
      <>(fn <<x,y,_>> when y == 0 -> <<x>>
            <<x,y,z>> when z == 0 -> <<x,y>>
            <<x,y,z>> -> <<x,y,z>> end).(h)
  end
end`}</pre>
</Route>
</Switch>
    </>)
}

export default Elixir
