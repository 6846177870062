import { useAsyncResource } from 'use-async-resource'
import 'react-gallery-carousel/dist/index.css'
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from 'react-router-dom'
import REF, { reader as refReader } from './birbs/REF'
import HU, { reader as huReader } from './birbs/HU'
import UKR, { reader as ukrReader } from './birbs/UKR'
import MEL, { reader as melReader } from './birbs/MEL'
import MISC, { reader as miscReader } from './birbs/MISC'
import REFClove from './birbs/REFClove'
import { style as birbstyle } from '../scale'

const Birbs = () => {
    const { path, url } = useRouteMatch()
    const [refBirbReader] = useAsyncResource(() => refReader(), [])
    const [huBirbReader]  = useAsyncResource(() => huReader(), [])
    const [melBirbReader] = useAsyncResource(() => melReader(), [])
    const [ukrBirbReader] = useAsyncResource(() => ukrReader(), [])
    const [mseBirbReader] = useAsyncResource(() => miscReader(), [])

    return <Router>
        <h1>Birb Pictures! <sup style={{fontSize:'smallest',fontStyle:'oblique'}}>No cats or dogs!</sup></h1>
        <div id="buttonrow" className="rowflex">
            <div><Link to={`${url}/ref`}><div className="icon"><img src="/gfx/birbs/icon-ref.jpg" alt="REF"/></div><br />REF</Link>
                &nbsp; | &nbsp;
                <Link to={`${url}/ref-clove`}>Clove&nbsp;(REF)</Link>
                &nbsp; | &nbsp;
                <Link to={`${url}/hu`}>HU</Link>
                &nbsp; | &nbsp;
                <Link to={`${url}/ukr`}>UKR</Link>
                &nbsp; | &nbsp;
                <Link to={`${url}/mel`}><div className="icon"><img src="/gfx/birbs/icon-mel.png" alt="MEL"/></div><br />MEL</Link>
                &nbsp; | &nbsp;
                <Link to={`${url}/mse`}>Misc</Link>
            </div>
        </div>
        <Switch>
            <Route path={`${path}/mse`}>
                <MISC reader={mseBirbReader} style={birbstyle}/>
            </Route>
            <Route path={`${path}/mel`}>
                <MEL reader={melBirbReader} style={birbstyle}/>
            </Route>
            <Route path={`${path}/ukr`}>
                <UKR reader={ukrBirbReader} style={birbstyle}/>
            </Route>
            <Route path={`${path}/hu`}>
                <HU reader={huBirbReader} style={birbstyle}/>
            </Route>
            <Route path={`${path}/ref-clove`}>
                <REFClove style={birbstyle}/>
            </Route>
            <Route path={`${path}/ref`}>
                <REF reader={refBirbReader} style={birbstyle}/>
            </Route>
            <Route exact path={path}>
                <p>
                I've been watching various bird (and other animals) related live
                streams for a &ldquo;while&rdquo; now and which has resulted in a bunch of pictures to keep.
                Poke a category and see for yourself!
                </p>
            </Route>
        </Switch>
    </Router>
}

export default Birbs
