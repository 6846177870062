import { Route, Switch } from 'react-router'
import { useRouteMatch, Link } from 'react-router-dom'
import Cpp from './c0d3x/cpp'
import Elixir from './c0d3x/elixir'
import Haskell from './c0d3x/haskell'
import Kotlin from './c0d3x/kotlin'

const Codex = () => {
    const { path, url } = useRouteMatch()

    return <div style={{paddingBottom:24}}>
    <h1>Code Snippets, etc.</h1>
    <h2><Link to={`${url}/libs`}>Libraries</Link>, etc.&#x21dd;</h2>
    <Switch>
        <Route path={`${path}/libs`}>
            <ul>
                <li>AKDice @ <a href="https://github.com/anikaiful/AKDice" target="ak_codex">GitHub.com</a></li>
                <li>AKDIce @ <a href="https://nuget.org/packages/AKDice/" target="ak_codex">NuGet.org</a></li>
            </ul>
        </Route>
    </Switch>

    <h2><Link to={`${url}/lang`}>Languages&hellip;&#x21dd;</Link></h2>
    <Switch>
        <Route path={`${path}/lang`}>
            <ul>
                <li><Link to={`${url}/cpp`}>C++</Link></li>
                <li><Link to={`${url}/elixir`}>Elixir</Link></li>
                <li><Link to={`${url}/haskell`}>Haskell</Link></li>
                <li><Link to={`${url}/kotlin`}>Kotlin</Link></li>
            </ul>
        </Route>
    </Switch>

    <Switch>
    <Route path={`${path}/cpp`}>
        <Cpp />
    </Route>
    <Route path={`${path}/elixir`}>
        <Elixir />
    </Route>
    <Route path={`${path}/haskell`}>
        <Haskell />
    </Route>
    <Route path={`${path}/kotlin`}>
        <Kotlin />
    </Route>
    </Switch>
</div>
}

export default Codex
