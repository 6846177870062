import React from 'react'
import { Route, Switch } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

const Kotlin = () => {
    const { path } = useRouteMatch()

    return(<>
{/* eslint-disable-next-line */}
<h1><a id="kotlin">Kotlin</a></h1>

<Switch>
    <Route path={`${path}/base64`}>
    </Route>
    <Route path={`${path}/`}>
{/* eslint-disable-next-line */}
<h2><a id="kotlin_base64">Base64</a></h2>
<pre>{`private const val BASE64_SET = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/"
private val RX_BASE64_CLEANR = "[^="+BASE64_SET+"]".toRegex()

/**
 * Base64 encode a string.
 */
val String.base64encoded: String get() {
    val pad  = when (this.length % 3) {
        1 -> "=="
        2 -> "="
        else -> ""
    }
    var raw = this
    (1 .. pad.length).forEach { raw += 0.toChar() }
    return StringBuilder().apply {
        (0 until raw.length step 3).forEach {
            val n: Int = (0xFF.and(raw[ it ].toInt()) shl 16) +
                         (0xFF.and(raw[it+1].toInt()) shl  8) +
                          0xFF.and(raw[it+2].toInt())
            append(BASE64_SET[(n shr 18) and 0x3F])
            append(BASE64_SET[(n shr 12) and 0x3F])
            append(BASE64_SET[(n shr  6) and 0x3F])
            append(BASE64_SET[(   n    ) and 0x3F])
            //listOf<Int>( (n shr 18) and 0x3F,
            //             (n shr 12) and 0x3F,
            //             (n shr  6) and 0x3F,
            //                 n      and 0x3F).forEach { append(BASE64_SET[it]) }
        }
    }   .dropLast(pad.length)
        .toString() + pad
}

/**
 * Decode a Base64 string.
 */
val String.base64decoded: String get() {
    if (this.length % 4 != 0)
        throw IllegalArgumentException("The string \\"\${this}\\" does not comply with BASE64 length requirement.")
    val clean = this.replace(RX_BASE64_CLEANR, "").replace("=", "A")
    val padLen = this.filter {it == '='}.length
    return StringBuilder().apply {
        (0 until clean.length step 4).forEach {
            val n: Int = (BASE64_SET.indexOf(clean[ it ]) shl 18) +
                         (BASE64_SET.indexOf(clean[it+1]) shl 12) +
                         (BASE64_SET.indexOf(clean[it+2]) shl  6) +
                          BASE64_SET.indexOf(clean[it+3])
            append(0xFF.and(n shr 16).toChar())
            append(0xFF.and(n shr  8).toChar())
            append(0xFF.and(   n    ).toChar())
            //listOf<Int>( 0xFF.and(n shr 16),
            //             0xFF.and(n shr  8),
            //             0xFF.and(   n    )).forEach { append(it.toChar()) }
        }
    }   .dropLast(padLen)
        .toString()
}`}</pre>

{/* eslint-disable-next-line */}
<h2><a id="gac_kotlin">Generic <em>Android</em> Application Core</a></h2>
<pre>{`// A.kt
package your.package.name
import android.app.Application
import android.content.Context
import android.content.res.Resources

class A: Application() {
    override fun onCreate() {
        super.onCreate()
        instance = this
    }

    companion object {
        lateinit var instance: A
            private set
        val context: Context get() = instance.applicationContext
        val resources: Resources get() = context.resources
    }
}`}
</pre>
How to use the above snippet, open <code>manifests/AndroidManifest.xml</code> and
insert <code>android:name="your.package.name.A"</code> inside <code>&lt;android&nbsp;...</code> tag as per:
<pre>{`<application
    android:name="your.package.name.A"
    ... >
    ...
</application>`}</pre>
<h2>Getting Stuff from Resources</h2>
<p><b>Note #1:</b> you need the code in <a href="#gac_kotlin"><code>Generic Application Core</code></a> for this piece to work.</p>
<p><b>Note #2:</b> do <em>not</em> attempt to use any of these within <code>companion object {'{'}...{'}'}</code>&ndash;blocks. Doing
so <em>will</em> crash your app sooner or later&mdash;most of the time &lsquo;sooner&rsquo;&hellip; in general before your app has
even launched properly.</p>
<p>These extension values/functions can be useful at places where direct access to e.g. <code>R</code> is not possible
	and/or desired.</p>
<pre>{`package your.package.name

/**
 * Get a resource string based on ID.
 */
val Int.s: String get() = A.resources.getString(this)

/**
 * Get a formatted resource string based on ID.
 */
fun Int.f(vararg x: Any): String = A.resources.getString(this, *x)

/**
 * Get an array of strings based on ID.
 */
val Int.a: Array&lt;String&gt; get() = A.resources.getStringArray(this)

/**
 * Get ID based on string (ID name).
 */
val String.id: Int get() = A.resources.getIdentifier(this, "string", A.context.packageName)

/**
 * Get plural's ID based on string (plural name).
 */
val String.idP: Int get() = A.resources.getIdentifier(this, "plurals", A.context.packageName)

/**
 * Get string array based on string (array name).
 */
val String.idA: Int get() = A.resources.getIdentifier(this, "array", A.context.packageName)

/**
 * Get quantity string based on ID and quantity.
 */
fun Int.qs(count: Int = 1): String = A.resources.getQuantityString(this, count, count)
`}</pre>
</Route>
</Switch>
</>)
}

export default Kotlin
