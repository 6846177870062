import { Suspense } from 'react'

import BirbCarousel from './BirbCarousel'
import Loading from '../Loading'
import { prettynum } from '../../scale'

const REF = ({reader, style}) => {
    const refUrl = "https://www.youtube.com/channel/UCU0LJ9et5Tb3m7COFzShgLg"

    const Handler = ({reader, style}) => {
        const data = reader()
        return <>
        <div className="carouser"><BirbCarousel json={data.b} style={style.carousel}/></div>
        <div style={style.div}>
            Have to love watching owls! And therefore &ldquo;here be birbs&rdquo;.
            They're not the only species around though; so far spotted:
                Barn owl,
                Tawny owl,
                Little owl,
                Kestrel,
                Pheasant,
                Fox,
                Gray Squirrel,
                Rat,
                Badger,
                Stoat,
                Weasel,
                Hedgehog,
                Roe Deer,
                Rabbit,
                Hare,
                European Jay,
                Wood Pigeon,
                and so on and so forth&hellip;
            <p>
                There's {prettynum(data.c)} pics ({data.s}) in this category (from August 2020 'til the present day and counting),
                so checking them all out will keep you entertained a &ldquo;while&rdquo;.
            </p>
            <p>
                But if checking static images isn't your thing, drop by at <a href={refUrl} target="_ref_birbs"><em><b>REF's</b> YouTube channel</em></a> for
                tons of great videos and couple 24/7 live feeds in which the wildlife themselves are main actors.
            </p>
        </div>
        </>
    }
    
    return <>
    <h2>Birbs! @ <a href={refUrl} target="_ref_birbs">Robert E Fuller, UK</a></h2>
    <div className="rowflex">
        <Suspense fallback={<Loading />}>
            <Handler reader={reader} style={style}/>
        </Suspense>
    </div>
    </>
}

export const reader = () => fetch("https://scifi.linkpc.net/gfx/birbs.php?ref").then(r => r.json())

export default REF
