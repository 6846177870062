import { Suspense } from 'react'

import BirbCarousel from './BirbCarousel'
import Loading from '../Loading'
import { prettynum } from '../../scale'

const MEL = ({reader, style}) => {
    const Handler = ({reader, style}) => {
        const data = reader()
        return <>
        <div className="carouser"><BirbCarousel json={data.b} style={style.carousel}/></div>
        <div style={style.div}>
            Melbourne, Australia, 2020&ndash;2021. A peek to lives of Peregrine falcons and their lil
            windosillosaurs.
            <p>{prettynum(data.c)} pics, {data.s} total.</p>
        </div>
        </>
    }

    return <>
    <h2>Birbs! @ Melbourne</h2>
    <div className="rowflex">
        <Suspense fallback={<Loading />}>
            <Handler reader={reader} style={style}/>
        </Suspense>
    </div>
    </>
}

export const reader = () => fetch("https://scifi.linkpc.net/gfx/birbs.php?mel").then(r => r.json())

export default MEL
