import { Suspense } from 'react'

import BirbCarousel from './BirbCarousel'
import Loading from '../Loading'
import { prettynum } from '../../scale'

const HU = ({reader, style}) => {
    const Handler = ({reader, style}) => {
        const data = reader()
        return <>
        <div className="carouser"><BirbCarousel json={data.b} style={style.carousel} /></div>
        <div style={style.div}>
            Six Hungarian owlets, autumn 2020. Out of 13 eggs laid, six hatched and grew into beautiful owls!
            <p>{prettynum(data.c)} pics, {data.s} total.</p>
        </div>
        </>
    }

    return <>
    <h2>Birbs! @ Hungary</h2>
    <div className="rowflex">
        <Suspense fallback={<Loading />}>
            <Handler reader={reader} style={style}/>
        </Suspense>
    </div>
    </>
}

export const reader = () => fetch("https://scifi.linkpc.net/gfx/birbs.php?hu" ).then(r => r.json())

export default HU
