import { useAsyncResource } from 'use-async-resource'
import Carousel from 'react-gallery-carousel'

import { Suspense } from 'react'
import Loading from './Loading'
import { prettynum, style } from '../scale'

const EdCarousel = ({json, style}) => {
    const imgs = []
    json.forEach(element => { imgs.push({src: `https://scifi.linkpc.net/ed/${element}`}) })
    return <Carousel images={imgs} style={style}
                     shouldLazyLoad={true}
                     hasIndexBoard={false}
                     hasMediaButton={false} />
}

const apireq='461b931815a6c9C1E7026BC2fdBC12102288755BCB5a1D8C27ed67B765f049F4818898A5038a750B76FEb9A2bf200ff75C90e7D59E4cAD0e1C99621Cb9fDc30C93E3aeb7082CB46412158468BBd5c5'

const ED = () => {
    const [reader] = useAsyncResource(() => fetch(`https://scifi.linkpc.net/ed/imager.php?${apireq}`).then(r=>r.json()), [])

    const Handler = ({reader, style}) => {
        const data = reader()

        return <>
            <div className="carouser"><EdCarousel json={data.i} style={style.carousel}/></div>
            <div style={style.div}>Over the course of a number of years
            playing <a href="https://www.elitedangerous.com"
                       target="elite_dangerous"
                       style={{textDecoration:'none'}}><em><b>Elite: Dangerous</b></em></a>,
            I've come upon at least {prettynum(data.c)} more or less interesting sights.
            There's about {data.s} of images, and thus &ndash;if you're on a metered connection&ndash; consider
            yourself warned.</div>
        </>
    }

    return <>
        <h1>Sightseeing in E:D</h1>
        <div className="rowflex">
            <Suspense fallback={<Loading />}>
                <Handler reader={reader} style={style} />
            </Suspense>
        </div>
    </>
}

export default ED
