import './Header.css'

const Header = () => {
    return <div style={{flex:1}}>
        <header className="App-header">
            <img src="/gfx/400.png" alt="anybody?" />
            <h1 style={{fontFamily: 'var(--base-font)'}}>Not a whole lot to see here!</h1>
        </header>
    </div>
}

export default Header
