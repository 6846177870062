import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Header from './components/Header'
import WF_CSS from './components/WfCss'
import Birbs from './components/Birbs'
import Menu from './components/Menu'
import ED from './components/ED'
import Codex from './components/Codex'

const App = () => {
	const options = [["birbs", "birbs!"],
	                 ["ed_ss", "E:D"],
					 ["c0d3x", "c0d3x"],
					 ["elsewhere", "elsewhere?"]
					]

	return (<Router>
		<Header />
		<Menu options={options} />
		<div className="App">
			<div className="App-content">
				<div className="oval"><span><img className="no-touch"
												 src="/gfx/aelinlin-11-sm.png"
												 alt="aelinlin"
												 style={{marginLeft: '80px'}} /></span></div>
				<h1>
					Or is there?
				</h1>
				<p>There might be?</p>
				<WF_CSS />
				<hr />

				<div className="App-sub-content">
					<Switch className="App-sub-content">
						<Route path="/birbs">
							<Birbs />
						</Route>
						<Route path="/elsewhere">
							<h1>Elsewhere!</h1>
							<p>Woohoo! You poked the 'elsewhere' link!</p>
							<p>And thus, you are elsewhere now. How quaint.</p>
						</Route>
						<Route path="/ed_ss">
							<ED />
						</Route>
						<Route path="/c0d3x">
							<Codex />
						</Route>
						<Route path="/">
							<h1>Yolo</h1>
							<p>As one might surmise, this is the front page. Look alive&mdash;poke a link or something!</p>
						</Route>
					</Switch>
				</div>
			</div>
		</div>
	</Router>)
}

export default App
